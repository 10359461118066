<template>
  <v-container v-if="customerRank !== '' && $can('read', 'CustomerService')" fluid tag="section">
    <v-card class="ma-0 mb-2">
      <v-card-title>
        <v-icon large color="blue" left>
          mdi-information-outline
        </v-icon>
        <span v-if="customerRank === 'prospect'">{{ $t('search.customer.rank.prospect') }}</span>
        <span v-else-if="customerRank === 'new'">{{ $t('search.customer.rank.new') }}</span>
        <span v-else-if="customerRank === 'occasional'">{{ $t('search.customer.rank.occasional') }}</span>
        <span v-else-if="customerRank === 'vip'">{{ $t('search.customer.rank.vip') }}</span>
        <span v-else-if="customerRank === 'inactive'">{{ $t('search.customer.rank.inactive') }}</span>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    name: 'CustomerRank',
    props: {
      orders: {
        type: Array
      },
      discounts: {
        type: Array
      },
      tasks: {
        type: Array
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        customerRank: ''
      };
    },
    watch: {
      refresh: function () {
        this.refreshData();
      }
    },
    mounted () {
      this.refreshData();
    },
    methods: {
      refreshData () {
        if (!Array.isArray(this.orders)) {
          return;
        }

        const orders = this.orders.filter((order) => Number(parseInt(order.totalPrice)) > 0);
        if (orders.length === 0) {
          this.customerRank = 'prospect';
        } else if (orders[0].createdAt < DateTime.utc().setZone('Europe/Paris').minus({ months: 3 }).toISO()) {
          this.customerRank = 'inactive';
        } else if (orders.length === 1) {
          this.customerRank = 'new';
        } else {
          const lastMonth = DateTime.utc().setZone('Europe/Paris').minus({ months: 1 }).toISO();
          const recentOrders = orders.filter((order) => order.createdAt >= lastMonth);
          if (recentOrders.length >= 2) {
            this.customerRank = 'vip';
          } else {
            this.customerRank = 'occasional';
          }
        }
      }
    }
  };
</script>
