<template>
  <v-container v-if="calls.length > 0" id="customer-service-calls" fluid tag="section">
    <v-card class="ma-0">
      <v-card-title>
        {{ $t('customerService.discounts.title') }}
      </v-card-title>
      <v-data-table :headers="headers" :options="options" :items="calls" :loading="loading" :hide-default-footer="true">
        <template #[`item.type`]="{ item }">
          {{ $t('customerService.discounts.type.' + item.type) }}
        </template>
        <template #[`item.phone`]="{ item }">
          <span v-html="formatPhone(item.phone)" />
        </template>
        <template #[`item.email`]="{ item }">
          <span v-html="formatEmail(item.email)" />
        </template>
        <template #[`item.value`]="{ item }">
          {{ formatValue(item.value, item.valueType) }}
        </template>
        <template #[`item.fromDate`]="{ item }">
          {{ formatDate(item.fromDate) }}
        </template>
        <template #[`item.toDate`]="{ item }">
          {{ formatDate(item.toDate) }}
        </template>
        <template #[`item.minimum`]="{ item }">
          {{ formatMinimum(item.minimum) }}
        </template>
        <template #[`item.usageLimit`]="{ item }">
          {{ item.usageLimit }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import { displayPrice } from '@/util/PriceUtil';

  export default {
    name: 'CustomerDiscounts',
    props: {
      data: {
        type: Array
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [
          {
            text: this.$i18n.t('customerService.discounts.table.type'),
            align: 'left',
            filterable: false,
            sortable: true,
            value: 'type'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.phone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'phone'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.email'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'email'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.value'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'value'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.code'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'code'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.fromDate'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'fromDate'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.toDate'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'toDate'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.minimum'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'minimum'
          },
          {
            text: this.$i18n.t('customerService.discounts.table.usageLimit'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'usageLimit'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['id'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        calls: []
      };
    },
    watch: {
      data: async function () {
        await this.refreshData();
      },
      refresh: async function () {
        await this.refreshData();
      }
    },
    async mounted () {
      await this.refreshData();
    },
    methods: {
      formatPhone (phone) {
        if (typeof phone !== 'string') {
          return '';
        }
        return '<a href="tel:' + phoneInt(phone) + '" target="_blank">' + displayPhone(phone) + '</a>';
      },
      formatEmail (email) {
        if (typeof email !== 'string') {
          return '';
        }
        return '<a href="mailto:' + email + '" target="_blank">' + email + '</a>';
      },
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      formatValue (value, valueType) {
        if (valueType === 'percentage') {
          return String(value) + '%';
        }
        return displayPrice(value, 'EUR');
      },
      formatMinimum (minimum) {
        if (typeof minimum !== 'number') {
          return '';
        }
        return displayPrice(minimum, 'EUR');
      },
      async refreshData () {
        if (Array.isArray(this.data)) {
          this.calls = this.data;
        }
      }
    }
  };
</script>
