<template>
  <v-container v-if="calls.length > 0" id="customer-service-calls" fluid tag="section">
    <v-card class="ma-0">
      <v-card-title>
        {{ $t('customerService.calls.title') }}
      </v-card-title>
      <v-data-table :headers="headers" :options="options" :items="calls" :loading="loading" :hide-default-footer="true">
        <template #[`item.way`]="{ item }">
          {{ $t('customerService.calls.way.' + item.way) }}
        </template>
        <template #[`item.phone`]="{ item }">
          <span v-html="formatPhone(item)" />
        </template>
        <template #[`item.state`]="{ item }">
          {{ $t('customerService.calls.state.' + item.state) }}
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';

  export default {
    name: 'CustomerServiceCalls',
    props: {
      data: {
        type: Array
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [
          {
            text: this.$i18n.t('customerService.calls.table.way'),
            align: 'left',
            filterable: false,
            sortable: true,
            value: 'way'
          },
          {
            text: this.$i18n.t('customerService.calls.table.phone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'phone'
          },
          {
            text: this.$i18n.t('customerService.calls.table.state'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'state'
          },
          {
            text: this.$i18n.t('customerService.calls.table.date'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'createdAt'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        calls: []
      };
    },
    watch: {
      data: async function () {
        await this.refreshData();
      },
      refresh: async function () {
        await this.refreshData();
      }
    },
    async mounted () {
      await this.refreshData();
    },
    methods: {
      formatPhone (item) {
        return '<a href="tel:' + phoneInt(item.phone) + '" target="_blank">' + displayPhone(item.phone) + '</a>';
      },
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      async refreshData () {
        if (Array.isArray(this.data)) {
          this.calls = this.data;
        }
      }
    }
  };
</script>
