<template>
  <v-container id="search" fluid tag="section">
    <alert-notification :message="alert" />
    <v-row v-if="!advancedSearch">
      <v-col cols="12">
        <section class="text-center">
          <v-text-field v-model="query" :label="$t('search.bar.label')" color="primary" hide-details autocomplete="off" style="max-width: 500px;" class="ma-auto" :clearable="true" @keyup.enter="search()">
            <template #append-outer>
              <v-btn class="mt-n2" elevation="1" fab small color="primary" @click="search()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </section>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-0 text-center">
        <v-btn v-if="!advancedSearch" color="accent" small text class="text-none" @click.stop="advancedSearch=true,query=''">
          {{ $t('search.button.advanced') }}
        </v-btn>
        <v-btn v-else color="accent" small text class="text-none" @click.stop="advancedSearch=false,orderName='',customerPhone=''">
          {{ $t('search.button.basic') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="advancedSearch" justify="center">
      <v-col cols="12" sm="12" md="4" class="pa-0">
        <v-text-field v-model.trim="orderName" :counter="orderNameMaxLength" :rules="orderNameRules" :label="$t('search.advanced.form.orderName.label')" :clearable="true" style="max-width: 90%"
                      class="ma-auto" autocomplete="off" @keyup.enter="search()" @keydown.space.prevent
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" class="pa-0">
        <v-text-field v-model.trim="customerPhone" :counter="customerPhoneMaxLength" :rules="customerPhoneRules" :label="$t('search.advanced.form.customerPhone.label')" :clearable="true" style="max-width: 90%"
                      class="ma-auto" autocomplete="off" @keyup.enter="search()" @keydown.space.prevent
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" class="pa-0">
        <v-text-field v-model.trim="customerEmail" :counter="customerEmailMaxLength" :rules="customerEmailRules" :label="$t('search.advanced.form.customerEmail.label')" :clearable="true" style="max-width: 90%"
                      class="ma-auto" autocomplete="off" @keyup.enter="search()" @keydown.space.prevent
        />
      </v-col>
      <v-col cols="12" class="text-center pa-2">
        <v-btn color="primary" @click="search()">
          <v-icon>mdi-magnify</v-icon> {{ $t('search.button.submit') }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="pt-5">
      <div v-if="loading">
        <v-row>
          <v-col cols="12">
            <v-progress-linear color="primary" indeterminate rounded height="10" />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <customer-rank v-if="showCustomerRank" :orders="orders" :discounts="discounts" :tasks="tasks" :refresh="timer" />
        <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows>
          <v-tab v-if="orders !== null">
            {{ $t('search.section.order.title') }}
          </v-tab>
          <v-tab v-if="$can('read', 'CustomerService') && customers !== null && customers.length > 0">
            {{ $t('search.section.customer.title') }}
          </v-tab>
          <v-tab v-if="$can('read', 'CustomerService') && discounts !== null && discounts.length > 0">
            {{ $t('search.section.discount.title') }}
          </v-tab>
          <v-tab v-if="$can('read', 'CustomerService') && tasks !== null && tasks.length > 0">
            {{ $t('search.section.task.title') }}
          </v-tab>
          <v-tab v-if="$can('read', 'CustomerService') && calls !== null && calls.length > 0">
            {{ $t('search.section.call.title') }}
          </v-tab>
          <v-tab-item v-if="orders !== null">
            <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
              <v-row v-if="orders.length === 0">
                <v-col cols="12">
                  <v-card class="pa-5">
                    <v-card-text class="text-center">
                      <base-subheading class="text-center" :subheading="$t('search.noOrder')" />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col v-for="order in orders" :key="order.id" cols="12" sm="6" md="4" lg="3" xl="3" class="pa-1">
                  <order-card :order="order" @refresh="search" />
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="$can('read', 'CustomerService') && customers !== null && customers.length > 0">
            <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
              <customers-view :customers="customers" :last-update="timer" :no-result-title="$t('customer.list.empty.title')" :no-result-description="$t('customer.list.empty.description')"
                              @success="success" @fail="fail"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="$can('read', 'CustomerService') && discounts !== null && discounts.length > 0">
            <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
              <customer-discounts :data="discounts" :refresh="timer" @success="success" @fail="fail" />
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="$can('read', 'CustomerService') && tasks !== null && tasks.length > 0">
            <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
              <customer-service-tasks :data="tasks" :editable="false" :refresh="timer" @success="success" @fail="fail" />
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="$can('read', 'CustomerService') && calls !== null && calls.length > 0">
            <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
              <customer-service-calls :data="calls" :refresh="timer" @success="success" @fail="fail" />
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </v-container>
</template>

<script>
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import { handleRequestError } from '@/services/common/Http';
  import CustomerRank from '@/views/components/business/customerService/CustomerRank';
  import CustomerDiscounts from '@/views/components/business/customerService/CustomerDiscounts';
  import CustomerServiceCalls from '@/views/components/business/customerService/CustomerServiceCalls';
  import CustomerServiceTasks from '@/views/components/business/customerService/CustomerServiceTasks';
  import CustomersView from '@/views/components/business/customer/CustomersView';
  import OrderCard from '@/views/components/business/order/OrderCard';

  export default {
    name: 'Search',
    components: { AlertNotification, CustomerRank, CustomerDiscounts, CustomerServiceCalls, CustomerServiceTasks, CustomersView, OrderCard },
    data () {
      return {
        alert: '',
        loading: false,
        advancedSearch: false,
        showCustomerRank: false,
        query: '',
        timer: undefined,
        orderName: '',
        orderNameMaxLength: 20,
        orderNameRules: [
          v => !v || (v && v.startsWith('#')) || this.$t('search.advanced.form.orderName.rule.starts')
        ],
        customerPhone: '',
        customerPhoneMaxLength: 12,
        customerPhoneRules: [
          v => !v || (v && v.startsWith('+')) || (v && v.startsWith('0')) || this.$t('search.advanced.form.customerPhone.rule.starts')
        ],
        customerEmail: '',
        customerEmailMaxLength: 100,
        customerEmailRules: [
          v => !v || (v && v.includes('@')) || this.$t('search.advanced.form.customerEmail.rule.starts')
        ],
        orders: null,
        customers: null,
        discounts: null,
        tasks: null,
        calls: null
      };
    },
    mounted: async function () {
      const name = decodeURIComponent(this.$route.query.name);
      if (typeof name === 'string' && name.startsWith('#') && name.length < 20) {
        this.orderName = name;
        this.advancedSearch = true;
        await this.search();
      } else {
        const phone = decodeURIComponent(this.$route.query.phone);
        if (typeof phone === 'string' && phone.startsWith('+') && phone.length < 20) {
          this.customerPhone = phone;
          this.advancedSearch = true;
          await this.search();
        } else {
          const email = decodeURIComponent(this.$route.query.email);
          if (typeof email === 'string' && email.includes('@') && email.length <= 100) {
            this.customerEmail = email;
            this.advancedSearch = true;
            await this.search();
          }
        }
      }
    },
    methods: {
      async search () {
        if (this.loading) {
          return;
        }

        this.stopAutoRefresh();
        this.loading = true;
        try {
          const searchType = (this.$store.state.location === undefined ? '&scope=locations' : '&scope=location');
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'v3/orders/search/?query=' + encodeURIComponent(this.query) + searchType
            + '&orderName=' + encodeURIComponent(this.orderName ?? '') + '&customerPhone=' + encodeURIComponent(this.customerPhone ?? '')
            + '&customerEmail=' + encodeURIComponent(this.customerEmail ?? ''));
          if (Array.isArray(response.data.orders)) {
            this.orders = response.data.orders;
          }
          if (Array.isArray(response.data.customers)) {
            this.customers = response.data.customers;
          }
          if (Array.isArray(response.data.discounts)) {
            this.discounts = response.data.discounts;
          }
          if (Array.isArray(response.data.tasks)) {
            this.tasks = response.data.tasks;
          }
          if (Array.isArray(response.data.calls)) {
            this.calls = response.data.calls;
          }
          this.alert = '';
          await this.startAutoRefresh();
        } catch (error) {
          this.orders = [];
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('search.error'));
        }
        this.loading = false;
        this.showCustomerRank = (typeof this.customerPhone === 'string' && this.customerPhone.length > 0) || (typeof this.customerEmail === 'string' && this.customerEmail.length > 0);
      },
      async startAutoRefresh () {
        if (!Array.isArray(this.orders) || this.orders.length === 0) {
          return;
        }

        this.timer = setTimeout(async () => {
          if (this.loading || this.orders.length === 0) {
            return;
          }

          try {
            const ids = this.orders.map((order) => 'ids[]=' + order.id).join('&');
            const scope = (this.$store.state.location === undefined ? '&scope=locations' : '&scope=location');
            const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders?' + ids + scope);

            if (Array.isArray(response.data) && ids === response.data.map((order) => 'ids[]=' + order.id).join('&')) {
              this.orders = response.data;
              this.alert = '';
            }
          } catch (error) {
            this.orders = [];
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('search.error'));
          }

          await this.startAutoRefresh();
        }, 15000);
      },
      stopAutoRefresh () {
        clearTimeout(this.timer);
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
