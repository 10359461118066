var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.calls.length > 0)?_c('v-container',{attrs:{"id":"customer-service-calls","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.calls.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.calls,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.way",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.calls.way.' + item.way))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item))}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.calls.state.' + item.state))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }