var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.calls.length > 0)?_c('v-container',{attrs:{"id":"customer-service-calls","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.discounts.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.calls,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.discounts.type.' + item.type))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.phone))}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatEmail(item.email))}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatValue(item.value, item.valueType))+" ")]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fromDate))+" ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.toDate))+" ")]}},{key:"item.minimum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMinimum(item.minimum))+" ")]}},{key:"item.usageLimit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usageLimit)+" ")]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }