import i18n from '@/i18n';

export const applyTaxRates = (priceTaxExcluded, rates, precision = 2) => {
  if (priceTaxExcluded === undefined || priceTaxExcluded === null) {
    return undefined;
  }

  let priceTaxIncluded = Number(priceTaxExcluded);
  if (rates !== undefined && rates.length > 0) {
    rates.forEach((rate) => {
      priceTaxIncluded += Number(priceTaxExcluded) * Number(rate);
    });
  }

  return Math.round(priceTaxIncluded * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const removeTaxRates = (priceTaxIncluded, rates, precision = 2) => {
  if (priceTaxIncluded === undefined || priceTaxIncluded === null) {
    return undefined;
  }

  let priceTaxExcluded = Number(priceTaxIncluded);
  if (Array.isArray(rates)) {
    rates.forEach((rate) => {
      priceTaxExcluded -= Number(priceTaxIncluded) - (Number(priceTaxIncluded) / (1 + Number(rate)));
    });
  }

  return Math.round(priceTaxExcluded * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const displayPrice = (price, currency) => {
  return String(price) + i18n.t('common.price.currency.' + currency + '.symbol');
};
