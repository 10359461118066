<template>
  <div class="customers-view">
    <alert-notification :message="alert" />
    <v-row v-if="lastUpdate === 0">
      <v-col cols="12">
        <v-progress-linear color="primary" indeterminate rounded height="10" />
      </v-col>
    </v-row>
    <div v-else>
      <div v-if="customersList.length === 0" class="pt-10 pb-10 text-center">
        <h1>{{ noResultTitle }}</h1>
        <p>{{ noResultDescription }}</p>
      </div>
      <v-row v-else>
        <v-col v-for="customer in customersList" :key="customer.id" cols="12" sm="6" md="4" :lg="Math.max(12 / Math.max(customersList.length, 3), 3)" :xl="Math.max(12 / Math.max(customersList.length, 3), 3)" class="px-1 py-0">
          <customer-card :customer="customer" @success="refresh" @fail="fail" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { isOnline, online, offline, isHidden, visibility } from '@/util/DeviceUtil';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import CustomerCard from '@/views/components/business/customer/CustomerCard';

  export default {
    name: 'CustomersView',
    components: { AlertNotification, CustomerCard },
    props: {
      customers: {
        type: Array,
        required: true
      },
      error: {
        type: String,
        default: ''
      },
      lastUpdate: {
        type: Number,
        required: true
      },
      noResultTitle: {
        type: String,
        required: true
      },
      noResultDescription: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        alert: '',
        customersList: [],
        resfreshTimeout: null
      };
    },
    watch: {
      error: function (message) {
        this.alert = message;
      },
      customers: function () {
        this.customersList = this.customers;
      }
    },
    mounted: function () {
      this.refresh();
      online(this.online, true);
      offline(this.offline, true);
      visibility(this.visibility, true);
    },
    destroyed: function () {
      this.cancelRefreshTimeout();
      online(this.online, false);
      offline(this.offline, false);
      visibility(this.visibility, false);
    },
    methods: {
      cancelRefreshTimeout () {
        if (this.resfreshTimeout !== null) {
          clearInterval(this.resfreshTimeout);
          this.resfreshTimeout = null;
        }
      },
      online () {
        this.refresh();
      },
      offline () {
        this.alert = this.$i18n.t('common.error.offline');
      },
      refresh () {
        this.cancelRefreshTimeout();

        if (isOnline() && !isHidden()) {
          this.alert = '';
          this.$emit('success');
        } else {
          this.offline();
        }

        this.customersList = this.customers;
        this.resfreshTimeout = setTimeout(() => {
          this.refresh();
        }, 15000);
      },
      fail (message) {
        this.alert = message;
      },
      visibility () {
        if (!isHidden()) {
          this.refresh();
        }
      }
    }
  };
</script>
